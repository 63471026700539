<template>
  <div>
    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12">
          <customer-view-customer-info-card :customer-data="userData" />
        </b-col>
      </b-row>

      <customer-treatment-list :mitarbeiterId="userData.id" :kundeId="userData.id"/>
    </template>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
import { BRow, BCol, BAlert, BLink } from "bootstrap-vue";
import CustomerTreatmentList from "@/views/customer/customers-treatments-list/CustomerTreatmentList.vue";
import customerStoreModule from "../customerStoreModule";
import CustomerViewCustomerInfoCard from "./CustomerViewCustomerInfoCard.vue";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    CustomerViewCustomerInfoCard,
    CustomerTreatmentList,
  },
  setup() {
    const userData = ref(null);

    const CUSTOMER_STORE_MODULE_NAME = "app-customer";

    // Register module
    if (!store.hasModule(CUSTOMER_STORE_MODULE_NAME))
      store.registerModule(CUSTOMER_STORE_MODULE_NAME, customerStoreModule);

    // Unregister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_STORE_MODULE_NAME))
        store.unregisterModule(CUSTOMER_STORE_MODULE_NAME);
    });

    store
      .dispatch("app-customer/fetchCustomer", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        userData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userData.value = undefined;
        }
      });

    return {
      userData,
    };
  },
};
</script>

<style>
</style>
