import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import moment from 'moment'
import i18n from '@/libs/i18n'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default function useTreatmentsList(props, allServices, mitarbeiters) {
  const toast = useToast()
  const refTreatmentListTable = ref(null)
  const kundeId = ref(JSON.parse(JSON.stringify(props.kundeId.value)))

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: false },
    { key: 'mitarbeiter_id', label: i18n.t('Customer'), sortable: false },
    { key: 'behandlung', label: i18n.t('Treatment'), sortable: false },
    { key: 'preis', label: i18n.t('Price'), sortable: false, formatter: val => `CHF ${parseFloat(val).toFixed(2)}` },
    { key: 'total_preis', label: i18n.t('Total Price'), sortable: false, formatter: val => `CHF ${parseFloat(val).toFixed(2)}` },
    { key: 'behandlungsdatum', label: i18n.t('Treatment Date'), sortable: false, formatter: val => moment(String(val)).format('DD.MM.YYYY') },
    { key: 'updated_at', label: i18n.t("Updated At"), sortable: false, formatter: val => moment(String(val)).format('DD.MM.YYYY hh:mm:ss') },
    { key: 'actions', label: i18n.t("Actions") },
  ]

  const perPage = ref(10)
  const totalTreatments = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refTreatmentListTable.value ? refTreatmentListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTreatments.value,
    }
  })

  const refetchData = () => {
    refTreatmentListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchCustomerTreatments = (ctx, callback) => {

    store
      .dispatch('app-customer/fetchCustomerTreatments', {
        kunde_id: kundeId.value,
        size: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then(response => {
        const responseData = response.data

        callback(responseData.data)
        totalTreatments.value = responseData.meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching treatments list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const resolveServiceName = serviceID => {
    var service = _.filter(
      allServices.value,
      (service) => service.ID == serviceID
    )[0];

    if (service) return service.ServiceName;
    return "Unknown";
  }

  const resolveMitarbeiterName = mitarbeiterID => {
    var mitarbeiter = _.filter(
      mitarbeiters.value,
      (mitarbeiter) => mitarbeiter.id == mitarbeiterID
    )[0];

    if (mitarbeiter) return mitarbeiter.emailadresse;
    return "Unknown";
  }

  return {
    fetchCustomerTreatments,
    tableColumns,
    perPage,
    currentPage,
    totalTreatments,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTreatmentListTable,
    refetchData,
    resolveServiceName,
    resolveMitarbeiterName
  }
}