import { ref, watch } from '@vue/composition-api'
// import store from '@/store'

export default function useTreatmentHandler(props, emit) {
  // ------------------------------------------------
  // treatmentLocal
  // ------------------------------------------------
  const treatmentLocal = ref(JSON.parse(JSON.stringify(props.treatment.value)))
  const resetTreatmentLocal = () => {
    var mitarbeiter_id = treatmentLocal.value.mitarbeiter_id;
    treatmentLocal.value = JSON.parse(JSON.stringify(props.treatment.value));
    treatmentLocal.value.mitarbeiter_id = mitarbeiter_id;
    treatmentLocal.value.total_preis = 0.0;
    treatmentLocal.value.preis = parseFloat(treatmentLocal.value.preis)
  }
  watch(props.treatment, () => {
    resetTreatmentLocal()
  })

  // ------------------------------------------------
  // isEventHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ! We can hide it using @hidden event
  // ------------------------------------------------
  // watch(props.isEventHandlerSidebarActive, val => {
  //   // ? Don't reset event till transition is finished
  //   if (!val) {
  //     setTimeout(() => {
  //       clearForm.value()
  //     }, 350)
  //   }
  // })

  const onSubmit = () => {
    const treatmentData = JSON.parse(JSON.stringify(treatmentLocal))

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.treatment.value.id) emit('update-treatment', treatmentData.value)
    else emit('add-treatment', treatmentData.value)

    // Close sidebar
    emit('update:is-treatment-handler-sidebar-active', false)
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // ------------------------------------------------
  // guestOptions
  // ------------------------------------------------


  return {
    treatmentLocal,
    resetTreatmentLocal,

    // UI
    onSubmit,
  }
}